<template>
<div class="row">
  <div class="col-lg">
    <CTabs variant="tabs" :active-tab.sync="activeTab">
      <CTab title="Request">
        <div class="tab-box" style="padding: 20px 20px 0px 20px">

            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <CDropdown color="primary" :toggler-text="method" :disabled="isExecuting">
                      <CDropdownItem @click="method = 'GET'">GET</CDropdownItem>
                      <CDropdownItem @click="method = 'POST'">POST</CDropdownItem>
                      <CDropdownItem @click="method = 'PUT'">PUT</CDropdownItem>
                      <CDropdownItem @click="method = 'DELETE'">DELETE</CDropdownItem>
                      <CDropdownItem @click="method = 'HEAD'">HEAD</CDropdownItem>
                    </CDropdown>
                  </div>
                  <input class="form-control" v-model="url" @focus="focusedURL" type="url" autocomplete="on" placeholder="Enter a URL" :disabled="isExecuting" @keyup.enter="execute()">
                  <div class="input-group-append">
                    <CButton @click="execute()" color="primary" :disabled="isExecuting || url.length == 0">
                      <div v-if="file">Upload File</div>
                      <div v-else>Execute</div>
                    </CButton>
                  </div>
                </div>
                <div style="height: 20px"></div>
                <textarea v-if="!file" v-bind:value="body" @input="updateBody" placeholder="Request body goes here" style="width: 100%; height: 150px; margin-bottom: 10px" :disabled="isExecuting || file"></textarea>
                <div style="margin-bottom: -10px">
                  <table width="100%">
                    <tr>
                      <td valign="middle" align="left" width="50%">
                        <div class="alert alert-secondary" style="border: 1px dotted gray" role="alert">
                          <span v-if="file" style="font-weight: bold; padding-right:10px"><a href="" @click="removeFile">Remove File</a></span>
                          <span v-else style="font-weight: bold; padding-right:10px">Upload File </span>
                          <input type="file" id="file" @change="attachFile">
                        </div>
                      </td>
                      <td valign="middle" align="right" width="50%">
                        Send session token with the request
                      </td>
                      <td width="10"></td>
                      <td>
                        <CSwitch color="primary" :checked.sync="includeSessionToken" value="color" labelOn="YES" labelOff="NO" :disabled="isExecuting" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </CTab>

      <CTab title="Response">
        <div class="tab-box" style="padding-top: 20px;">

          <div v-if="isExecuting">
            <div class="spinner-border spinner-border-sm text-primary" role="status">
              <span class="sr-only"></span>
            </div>
          </div>

          <div v-else-if="errorMessage">
            <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
          </div>

          <div v-else>
            <div v-if="hasExecuted" style="padding: 0px 10px 10px 10px">
              <table width="100%" border="0">
                <tr>
                  <td width="70">
                    <div v-if="responseCode" style="padding: 0px 0px 0px 0px; font-size: 20px;">
                      <span v-if="responseCode >= 200 && responseCode < 300" class="badge badge-pill badge-success">{{ responseCode }}</span>
                      <span v-else-if="responseCode >= 300 && responseCode < 500" class="badge badge-pill badge-warning">{{ responseCode }}</span>
                      <span v-else-if="responseCode >= 500" class="badge badge-pill badge-danger">{{ responseCode }}</span>
                      <span v-else class="badge badge-pill badge-secondary">{{ responseCode }}</span>
                    </div>
                  </td>
                  <td>
                    <span style="font-family: monospace">{{ url }}</span>
                  </td>
                  <td align="right">

                    <CButton @click="execute()" color="primary" :disabled="isExecuting || url.length == 0">
                      <div v-if="file">Upload Again</div>
                      <div v-else>Execute Again</div>
                    </CButton>

                  </td>
                </tr>
              </table>
              <div style="font-family:monospace; width: 100%; white-space: pre; border: 2px dashed #C0C0C0; margin-top: 15px; padding: 10px; overflow: hidden; overflow-x: auto;" v-html="responseBody"></div>
            </div>
            <div v-else style="margin-bottom: -10px">
              <div class="alert alert-secondary" role="alert">No requests have been made</div>
            </div>
          </div>

        </div>
      </CTab>

      <CTab title="Recent">
        <div class="tab-box" style="padding-top: 20px;">

          <div v-if="recents.length > 0" style="padding: 0px 10px 20px 10px">
            <table cellpadding="5">
              <thead>
                <tr>
                  <th>Method</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="recent in recents" class="clickable-row" style="cursor: text" @click="loadRequest(recent)">
                  <td width="80">
                    <span class="badge badge-pill badge-success">{{ recent.method }}</span>
                  </td>
                  <td>
                    {{ recent.url}}
                  </td>
                  <td width="100" align="right">
                    <div v-if="recent.uploadFile">
                      <CButton color="secondary" style="width: 100%" @click="loadRequest(recent)">Upload</CButton>
                    </div>
                    <div v-else>
                      <CButton color="primary" style="width: 100%"  @click="execute(recent)">Execute</CButton>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else style="margin-bottom: -10px">
            <div class="alert alert-secondary" role="alert">No recent requests</div>
          </div>

        </div>
      </CTab>

    </CTabs>

  </div>
</div>
</template>

<script>
export default {
  name: 'API-Tester',
  data() {
    return {
      activeTab: 0,
      body: "",
      defaultURL: "https://auth.platform.robocalls.ai/account",
      errorMessage: null,
      file: null,
      hasExecuted: false,
      includeSessionToken: true,
      isExecuting: false,
      method: "GET",
      recents: [],
      responseBody: null,
      responseCode: null,
      url: ""
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    execute: function(request) {

      if (this.isExecuting) {
        return;
      } else {
        this.hasExecuted = true;
        this.isExecuting = true;
        this.activeTab = 1;
      }

      this.errorMessage = null;
      this.responseBody = null;
      this.responseCode = null;

      if (request) {
        this.method = request.method;
        this.url = request.url;
        this.body = request.body;
        this.file = request.file;
        this.includeSessionToken = request.includeSessionToken;
      }

      var that = this;
      if (this.file) {
        // Execute the request by uploading a file
        RobocallsAI.shared().upload(this.method, this.url, this.file, this.includeSessionToken, {
          onSuccess: function(code, body) {
            // Code = HTTP status code
            // Body = Response from server
            that.isExecuting = false;
            that.responseCode = code;
            that.responseBody = that.htmlEntities(body);

            // Add the request to recents
            that.storeRequest();
          },
          onCancelled: function() {
            // Fired if the request was cancelled
            that.isExecuting = false;
          },
          onFailure: function(error) {
            // Fired if the request fails
            that.isExecuting = false;
            that.errorMessage = error;
          }
        })
      } else {
        // Execute the request
        RobocallsAI.shared().execute(this.method, this.url, this.body, this.includeSessionToken, {
          onSuccess: function(code, body) {
            // Code = HTTP status code
            // Body = Response from server
            that.isExecuting = false;
            that.responseCode = code;
            that.responseBody = that.htmlEntities(body);

            // Add the request to recents
            that.storeRequest();
          },
          onCancelled: function() {
            // Fired if the request was cancelled
            that.isExecuting = false;
          },
          onFailure: function(error) {
            // Fired if the request fails
            that.isExecuting = false;
            that.errorMessage = error;
          }
        })
      }
    },
    //--------------------------------------------------------------------------
    focusedURL: function(e) {
      if (this.url == this.defaultURL && !this.hasExecuted) {
        this.url = "https://";
      }
    },
    //--------------------------------------------------------------------------
    htmlEntities(str) {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    },
    //--------------------------------------------------------------------------
    loadRecents() {
      // Load the recents from disk
      var result = StorageManager.shared().getItem("api-tester::recents");

      // Decode the result
      try {
        result = JSON.parse(result);
      } catch (err) {
        result = null;
      }

      // Make sure the data is valid
      if (!result || !Array.isArray(result)) {
        this.recents = [];
      } else {
        this.recents = result;
      }
    },
    //--------------------------------------------------------------------------
    loadRequest(request) {
      this.method = request.method;
      this.url = request.url;
      this.body = request.body;
      document.getElementById("file").value = '';
      this.file = null;
      this.includeSessionToken = request.includeSessionToken;
      if (!this.isExecuting) {
        this.activeTab = 0;
      }
    },
    //--------------------------------------------------------------------------
    storeRequest() {
      // Build the request
      var request = {
        method: this.method,
        url: this.url,
        body: this.body,
        includeSessionToken: this.includeSessionToken
      };

      //
      if (this.file) {
        request.body = null;
        request.uploadFile = true;
      }

      // Remove any recent requests that match the current requests URL
      var modifiedRecents = [];
      for (var x = 0; x < this.recents.length; x++) {
        if (this.recents[x].url == request.url) {
          if (this.recents[x].method != request.method) {
            modifiedRecents.push(this.recents[x]);
          }
        } else {
          modifiedRecents.push(this.recents[x]);
        }
      }
      modifiedRecents.unshift(request);
      this.recents = modifiedRecents;

      // Add the request to the beginning of recents and store to disk
      StorageManager.shared().setSessionItem("api-tester::recents", JSON.stringify(this.recents));
    },
    //--------------------------------------------------------------------------
    updateBody(e) {
      this.body = e.target.value;
    },
    //--------------------------------------------------------------------------
    attachFile(e) {
      if (e.target.files.length >= 1) {
        this.file = e.target.files[0];
      } else {
        this.file = null;
      }
    },
    //--------------------------------------------------------------------------
    removeFile(e) {
      this.file = null;
      document.getElementById("file").value = '';
      e.preventDefault();
    }
  },
  mounted() {
    this.url = this.defaultURL;
    this.loadRecents();
  }
}
</script>
